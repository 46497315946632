<template>
  <div
    class="shop-product-card-store"
    :class="{
      'is-event': item.type === 'event' || item.type === 'consultation',
    }"
  >
    <AtomImage
      v-if="item.location.image"
      :src="item.location.image"
      class="shop-product-card-store__image"
    />

    <div class="shop-product-card-store__name">
      {{ useTranslation('general', 'in') }}

      <span
        class="shop-product-card-store__link"
        @click="navigateToSpeaker($event)"
        @keypress="navigateToSpeaker($event)"
      >
        {{ `${item.location.name} ${item.location.place}` }}
      </span>
    </div>
  </div>
</template>

<script setup>
const item = inject('item');

const navigateToSpeaker = ($event) => {
    $event.stopPropagation();
    if (item.location?.sb_slug) {
        navigateTo(`../${item.location.sb_slug}`);
    }
};
</script>

<style lang="scss" scoped>
.shop-product-card-store {
    @include typo-font('regular');
    @include typo-size('a');

    display: flex;
    align-items: center;
    margin-bottom: 20px;
    column-gap: 10px;

    &.is-event {
        position: absolute;
        z-index: 9;
        bottom: 0;
        left: 0;
        padding: 11px 20px;
        border-radius: 0px 6px;
        margin-bottom: 0;
        background: $C_GREEN_BRIGHT;
    }
}

.shop-product-card-store__image {
    overflow: hidden;
    width: 31px;
    height: 31px;
    border-radius: 50px;
}

.shop-product-card-store__link {
    color: $C_PRIMARY;
}
</style>
